/** @jsx jsx */
import { jsx } from 'theme-ui'
// import { useForm, ValidationError } from '@formspree/react';
import {
  Button,
  Label,
  Input,
  Textarea,
} from '../../../node_modules/@theme-ui/components'

const Form = () => {
  return (
    <form
      // onSubmit={handleSubmit}
      action='https://formspree.io/f/mgervkya'
      method='POST'
      id='rice-contact'
    >
      <Label htmlFor='name'>Your name</Label>
      <Input type='text' name='name' mb={3} />

      <Label htmlFor='email'>Your email</Label>
      <Input type='email' name='email' mb={3} />

      <input type='text' name='_gotcha' sx={{ display: 'none' }} />

      <Label htmlFor='message'>Your message</Label>
      <Textarea name='message' rows='6' mb={3} />

      <Button type='submit'>Send</Button>
    </form>
  )
}

export default Form
