/** @jsx jsx */
import { jsx } from "theme-ui"
// import { useForm, ValidationError } from '@formspree/react';
import {
  Button,
  Label,
  Input
} from "../../../../node_modules/@theme-ui/components"

const FormSari = () => {
  return (
    <form
      // onSubmit={handleSubmit}
      action='https://formspree.io/f/xeqvrjaz'
      method='POST'
      id='rice-contact'>
      <Label htmlFor='name'>Your name</Label>
      <Input type='text' name='name' mb={3} />

      <Label htmlFor='email'>Your email</Label>
      <Input type='email' name='email' mb={3} />

      <input type='text' name='_gotcha' sx={{ display: "none" }} />

      <Label htmlFor='name'>Your Occupation</Label>
      <Input type='text' name='occupation' mb={3} />

      <Label htmlFor='name'>
        Your Institution (if none, please write "independent researcher")
      </Label>
      <Input type='text' name='affiliation' mb={3} />

      <Button type='submit'>Submit</Button>
    </form>
  )
}

export default FormSari
